<template>
  <div class="container text-white p-0">
    <div id="contact" class="col-sm-12 case">
      <div id="horraire" class="container-fluid text-center">
        <h2>HORAIRES</h2>
        <table class="table table-dark">
          <tr>
            <th>Lundi</th>
            <td>Fermé*</td>
          </tr>
          <tr>
            <th>Mardi</th>
            <td>11h30-14h30 19h-22h30</td>
          </tr>
          <tr>
            <th>Mercredi</th>
            <td>11h30-14h30 19h-22h30</td>
          </tr>
          <tr>
            <th>Jeudi</th>
            <td>11h30-14h30 19h-22h30</td>
          </tr>
          <tr>
            <th>Vendredi</th>
            <td>11h30-14h30 19h-22h30</td>
          </tr>
          <tr>
            <th>Samedi</th>
            <td>11h30-14h30 19h-22h30</td>
          </tr>
          <tr>
            <th>Dimanche</th>
            <td>Fermé*</td>
          </tr>

        </table>
        <p style="color: #95604b;">*Possibilité de réservation pour des évenements (fiançailles, baptêmes,...)</p>
      </div>
      <div id="adresse" class="container-fluid text-center">
        <h2>ADRESSE</h2>
        <p>Impasse de la Croix Verte, 95570 Attainville</p>
        <p>mata.croixverte@gmail.com</p>
        <h2>01.39.88.23.39</h2>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <iframe id="maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2614.3022897951237!2d2.32887131584274!3d49.061888394310216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e643cb219071d1%3A0x19ff029dab1191ce!2sMata!5e0!3m2!1sfr!2sfr!4v1595268173223!5m2!1sfr!2sfr"
                height="450" style="border:0;" allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe>
      </div>
    </div>

    <div class="container">
      <div class="d-flex justify-content-center">
        <button style="background-color: #95604b;border-color: #4e555b;margin-bottom: 5px;"
                class="btn btn-primary" type="button" data-bs-toggle="collapse"
                data-bs-target="#mentions" aria-expanded="false" aria-controls="mentions">Mentions légales
        </button>
      </div>

      <div id="mentions" class="collapse">
        <div class="card card-body text-black">
          <p>Dénomination sociale: SASU M.A.C </p>
          <p>Numéro de siret: 832 873 210 R.C.S Pontoise</p>
          <p>Capital social: 5000,00 euros</p>
          <p>Numéro de TVA: FR37832873210 </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ContactView"
}
</script>

<style scoped>
</style>
