<template>
  <header-component></header-component>
  <router-view id="routerView"></router-view>
  <footer-component></footer-component>
</template>

<script>
import HeaderComponent from "@/components/shared/HeaderComponent.vue";
import FooterComponent from "@/components/shared/FooterComponent.vue";

export default {
  name: 'App',
  components: {FooterComponent, HeaderComponent},
}
</script>

<style>
#app, html {
  background: black;
}

#routerView{
  margin-top: 70px;
  margin-bottom: 50px;
}

</style>
