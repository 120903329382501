import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/views/HomeView";
import ImageView from "@/views/ImageView";
import ContactView from "@/views/ContactView";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/photos',
        name: 'Image',
        component: ImageView
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactView
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.name) {
        next({ name: 'Home' });
    } else {
        next();
    }
});

export default router
