<template>
  <div class="container p-0">
    <div class="col-md-12">
      <div class="container-fluid text-center">
        <img src="../../public/assets/img/MATA-logo.png" width="100%" alt="mata logo">
        <h5 id="specialiteText" class="text-white">Spécialités assyro-chaldéennes</h5>
      </div>
    </div>

    <div class="col-sm-12 case">
      <a href="/assets/pdf/Menu_MATA.pdf" target="_blank" class="nav-link">
        <div id="section2" class="container-fluid text-center">
          <h3 class="titreSection">MENU</h3>
        </div>
      </a>
    </div>

    <div class="col-sm-12 case">
      <router-link to="/photos">
        <div id="section3" class="container-fluid text-center">
          <h3 class="titreSection">PHOTOS</h3>
        </div>
      </router-link>
    </div>

    <div class="col-sm-12 case">
      <router-link to="/contact">
        <div id="section4" class="container-fluid text-center">
          <h3 class="titreSection">CONTACT</h3>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView"
}
</script>

<style scoped>
.case{
  padding:0;
}

#section2 {
  width: 95%;
  margin-top:5px;
  margin-bottom:5px;
  padding-top: 15%;
  padding-bottom: 15%;
  border-radius: 5px;
  border: solid #16181b 1px;
  color: black;
  background: url('../../public/assets/img/3.jpg') no-repeat;
  background-size: cover;
}
#section2:hover{
  opacity: 0.8;
  background-color: white;
  box-shadow: white;
}
#section3 {
  width: 95%;
  margin-top:5px;
  margin-bottom:5px;
  padding-top: 15%;
  padding-bottom: 15%;
  border-radius: 5px;
  border: solid #16181b 1px;
  color: black;
  background: url('../../public/assets/img/1.jpg') no-repeat;
  background-size: cover;
}
#section3:hover{
  opacity: 0.8;
  background-color: black;
}
#section4 {
  width: 95%;
  padding-top: 15%;
  padding-bottom: 15%;
  border-radius: 5px;
  border: solid #16181b 1px;
  color: black;
  background: url('../../public/assets/img/2.jpg') no-repeat;
  background-size: cover;
}
#section4:hover{
  opacity: 0.8;
  background-color: white;
  box-shadow: white;
}
.titreSection{
  font-size: 25px;
  color: white;
  width: 100%;
  background-color: black;
  border-radius: 3px;
  padding: 3px;
  font-weight: lighter;
  opacity: 0.75;
}

#specialiteText {
  margin-bottom: 20px;
  margin-top: 0;
  font-family:'Marcellus',serif
}

@font-face {
  font-family: "Marcellus";
  src: url('../../public/assets/polices/MarcellusSC-Regular.ttf');
}

a:link {
  text-decoration:none;
  color: black;
}
</style>
