<template>
  <nav class="navbar navbar-dark bg-dark fixed-top" >
    <div class="container-fluid">
      <a class="navbar-brand">
        <router-link to="/">
          <img src="/assets/img/MATA-logo.png" width="80" alt="Mata Logo"/>
        </router-link>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#myNavbar"
              aria-controls="myNavbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="navbar-nav me-auto my-2 my-lg-0">
          <li class="nav-item align-self-center">
            <a href="/assets/pdf/Menu_MATA.pdf" target="_blank" class="nav-link">Menu</a>
          </li>
          <li class="nav-item align-self-center">
            <router-link class="nav-link" to="/photos">Photos</router-link>
          </li>
          <li class="nav-item align-self-center">
            <router-link class="nav-link" to="/contact">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style scoped>
</style>
