<template>

  <div class="container text-center text-white">

    <div class="col-12 ">
      <h2>Plateaux apéritifs</h2>
    </div>
    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/PlateauHanniya.jpg" alt="Plateau Hanniya" style="width:100%">
        <div>
          <p>Plateau Hanniya (plateau froid) :<br>
            - Purée de pois chiches<br>
            - Tomates émincées<br>
            - Caviar d'aubergines.</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/PlateauMata.jpg" alt="Plateau Mata" style="width:100%">
        <div>
          <p>Plateau Mata (plateau chaud) :<br>
            - Ketlatas (Chaussons à base de semoule fourrés à la viande hachée de boeuf et aux légumes)<br>
            - Beureks (Roulés au fromage)<br>
            - Lahmacun (Viande hachée de boeuf à la sauce tomate sur un pain traditionnel fait maison)<br>
            - Kadekta (Pain maison fourré au fromage).</p>
        </div>
      </div>
    </div>

    <div class="col-12 text-white">
      <h2>Entrées</h2>
    </div>
    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/Madira.jpg" alt="Madira" style="width:100%">
        <div>
          <p>Madira (Soupe à base de yaourt, blé et menthe).</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/Djadjik.jpg" alt="Djadjik" style="width:100%">
        <div>
          <p>Djadjik (Yaourt maison et concombre, accompagné de pain traditionnel fait maison). </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/SaladeQalya.jpg" alt="Salade Qalya" style="width:100%">
        <div>
          <p>Salade Qalya (Salade verte, légumes grillés, émincés de viandes). </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/SaladeKetlatas.jpg" alt="Salade Ketlatas" style="width:100%">
        <div>
          <p>Salade Ketlatas (Salade mixte, olives, accompagnée de Ketlatas).</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/SaladeEtBeureks.jpg" alt="Salade et Beureks" style="width:100%">
        <div>
          <p>Salade & Beureks (Salade mixte, Beureks, tomates, pommes, noix, miel).</p>
        </div>
      </div>
    </div>

    <div class="col-12 text-white">
      <h2>Plats</h2>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/BalolaCoupé.jpg" alt="Balola" style="width:100%">
        <div>
          <p>Balola "boeuf" ou "poulet" (Viande hachée de boeuf ou émincé de poulet mariné, légumes grillés, crème
            fraiche, fromage, enroulés d'un pain traditionnel grillé fait maison).</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/Matfouniye.jpg" alt="Matfouniye" style="width:100%">
        <div>
          <p>Matfouniye (Viande d'agneau et haricots blancs dans leurs sauce tomate).</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/AprakhésEtPoulet.jpg" alt="Aprakhés et Poulet" style="width:100%">
        <div>
          <p>Aprakhés & Grillades (Roulés de feuilles de vigne farcies de riz à la sauce tomate et légumes émincés,
            accompagnés de brochettes de poulet mariné ou boeuf ou Adana ou Agneau).</p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/QâchqaEtCotelettes.jpg" alt="Qâchqa et Cotelettes" style="width:100%">
        <div>
          <p>Qâchqa & Grillades (Mijoté de maïs façon grand-mère, accompagné de brochettes de poulet mariné ou boeuf
            ou Adana ou Agneau).</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/TastadMatfouniye.jpg" alt="Tasta d'Matfouniye" style="width:100%">
        <div>
          <p>Tasta d'Matfouniye (Viande hachée de boeuf, enroulée de légumes, cuite au four).</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/AssietteDécouverte.jpg" alt="Assiette Découverte" style="width:100%">
        <div>
          <p>Assiette découverte :<br>
            - Aprakhés (Roulés de feuilles de vigne farcies de riz à la sauce tomate et légumes émincés)<br>
            - Qâchqa (Mijoté de maïs façon grand-mère)<br>
            - Tasta d'Matfouniyé (Viande hachée de boeuf, enroulée de légumes, cuite au four).</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/AssietteMixte.jpg" alt="Assiette Mixte" style="width:100%">
        <div>
          <p>Assiette Mixte :<br>
            - Brochettes de poulet mariné<br>
            - Brochettes de boeuf<br>
            - Brochettes «Adana» (Viande hachée de boeuf épicée).</p>
        </div>
      </div>
    </div>

    <div class="col-12 text-white">
      <h2>Desserts</h2>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/Glace3boules.jpg" alt="Glace 3 boules" style="width:100%">
        <div>
          <p>Coupe de glace 3 boules.</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/CaféGourmand.jpg" alt="Café Gourmand" style="width:100%">
        <div>
          <p>Café Gourmand.</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/SaladeFruits.jpg" alt="Salade Fruits" style="width:100%">
        <div>
          <p>Salade de fruits frais.</p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/Baklawa.jpg" alt="Baklawa" style="width:100%">
        <div>
          <p>Baklawa (Feuilleté aux pistaches et noix accompagné d'un yaourt traditionnel fait maison).</p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/Guerdo.jpg" alt="Guerdo" style="width:100%">
        <div>
          <p>Guerdo (Riz au lait traditionnel, sirop fait maison et beurre fondu). </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="text-opacity-50">
        <img src="/assets/img/Künefe.jpg" alt="Künefe" style="width:100%">
        <div>
          <p>Künefe (Fromage fondu entre deux couches de cheveux d'anges au sirop). </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: "ImageView",
  components: {}
}
</script>

<style scoped>
</style>
