<template>
  <footer class="fixed-bottom d-flex justify-content-center m-0">
    <a href="https://www.instagram.com/mata.attainville/" id="instagram" class="m-2">
      <font-awesome-icon class="fa-2x" icon="fa-brands fa-instagram"/>
    </a>
    <a href="https://fr-fr.facebook.com/mata.attainville/" class="m-2">
      <font-awesome-icon class="fa-2x" icon="fa-brands fa-facebook"/>
    </a>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped>
#instagram{
  color: mediumorchid;
}
#instagram:hover{
  color: purple;
}
footer{
  background-color: #212529;
}

</style>
